import { CosmosEnums } from 'cosmos-components';
import { SciPagesEnum } from './components/shared/SciPage/types';
import { SciFlow } from './types/SciFlow';
/**
 * New Zealand locale is hardcoded until we agreed on a strategy
 * of selecting locale dynamically based on a several parameters.
 * Whether navigator.language and navigator.languages are important,
 * we should also consider adding Accept-Language header and
 * reading user settings coming from the back-end. There is also could be
 * a UI control to change locale/currency.
 */
export const DEFAULT_LOCAL = 'en';

export const languageOptions = [
    {
        id: 'en_US',
        label: 'EN',
        value: 'English',
    },
    {
        id: 'fr-ca',
        label: 'FR',
        value: 'French',
    },
];

export const acceptedlanguages = languageOptions.map((opt) =>
    opt.label?.toLowerCase(),
);

export const minimumPaymentAmount = 0.1;

export const excludeLocationCodesForRNZExternalAgent = [
    'chs',
    'zqs',
    'aks',
    'fox',
    'bch',
    'cam',
    'nsn',
    'dun',
    'ptn',
    'gre',
    'rot',
    'ivc',
    'wel',
    'hst',
    'eca',
];

export const excludeLocationCodesForRNZInternalAgent = ['chs', 'zqs', 'aks'];

export const ThlWebsiteUrl =
    'http://www.thlonline.com/Privacy/Pages/default.aspx';

export const ThlWebChatUrl =
    'https://cornerstonecx.co.nz/symbee-connect-chatclient/chatClient/thlonline/prod-sso/SelfCheckIn?QueueName=SelfCheckIn';

export const ThlEmailUsUrl = 'mailto:reservationsusa@thlonline.com';
export const RoadBearEmailUrl = 'mailto:selfcheckin-help-usa@thlonline.com';
export const ElMonteEmailUrl = 'mailto:selfcheckin-help-usa@thlonline.com';
export const EuropeEmailUrl = 'mailto:enquiriesEU@thlonline.com';
export const CanaDreamEmailUrl = 'mailto:booking@canadream.com';

export const CountryName = {
    nz: 'New Zealand',
    au: 'Australia',
    cn: 'China',
    us: 'United States',
    ca: 'Canada',
    gb: 'Great Britain',
};

export const CountryCode = {
    nz: 'NZ',
    au: 'AU',
    cn: 'CN',
    us: 'US',
    ca: 'CA',
    gb: 'GB',
};

export const favoriteCountriesWithCodes = [
    { name: CountryName.nz, code: 'NZ' },
    { name: CountryName.au, code: 'AU' },
    { name: CountryName.cn, code: 'CN' },
    { name: CountryName.us, code: 'US' },
    { name: CountryName.gb, code: 'GB' },
    { name: CountryName.ca, code: 'CA' },
];

export const defaultMinAge = 21;

export const StateCountries = [CountryName.us, CountryName.au, CountryName.ca];

export const StateCountryCodes = [
    CountryCode.us,
    CountryCode.au,
    CountryCode.ca,
];

export const UNITEDSTATES = 0;

export const AUSTRALIA = 1;

export const CANADA = 2;

export const sciContentKeys = {
    sciCustomerDetailsPage: 'sci-customer-details-page',
    sciDriverDetailsPage: 'sci-driver-details-page',
    sciAdditionalDriverDetailsPage: 'sci-additional-driver-details-page',
    sciAccidentLiabilityPage: 'sci-accident-liability-page',
    sciExtrasPage: 'sci-extras-page',
    sciPaymentPage: 'sci-payments-page',
    termsAndConditionsAcceptance: 'terms-and-conditions-acceptance',
    sciSafetyVideosAcceptance: 'sci-safety-videos-acceptance',
    infoBoxCardAdminFee: 'info-box-card-admin-fee',
    sciBankTransferConfirmation: 'sci-bank-transfer-confirmation',
    sciOnlinePaymentConfirmation: 'sci-online-payment-confirmation',
    sciConfirmationPage: 'sci-confirmation-page',
    bankTransferInfo: 'bank-transfer-info',
    sciInfoBoxCustomerDetails: 'sci-info-box-customer-details',
    sciInfoBoxDriverDetails: 'sci-info-box-driver-details',
    sciInfoBoxAdditionalDriverDetails: 'sci-info-box-additional-driver-details',
} as const;

export const organizationIds = {
    [CosmosEnums.CountryCode.AU]: 'ff27eac8-a949-4d79-aa2d-87d9a4886eb5',
    [CosmosEnums.CountryCode.NZ]: '98eb24cc-ec7a-4f22-84a5-6b8209d3d166',
    [CosmosEnums.CountryCode.US]: '478242c4-3cbe-48af-9180-1e330e0b83d3',
    [CosmosEnums.CountryCode.UK]: '4e270349-56e5-4dea-b951-f9ef9ad8a1de',
    [CosmosEnums.CountryCode.CA]: 'b446b758-b8f6-4b65-afba-04dc1f08faa6',
};

export const currencySymbols = {
    [CosmosEnums.Currencies.AUD]: '$',
    [CosmosEnums.Currencies.NZD]: '$',
    [CosmosEnums.Currencies.USD]: '$',
    [CosmosEnums.Currencies.EUR]: '€',
    [CosmosEnums.Currencies.GBP]: '£',
    [CosmosEnums.Currencies.CAD]: '$',
};

export const Path = {
    [SciPagesEnum.UpdateCustomerPage]: '/updateCustomerInfo',
    [SciPagesEnum.AddDriverPage]: '/addDriver',
    [SciPagesEnum.AddAdditionalDriversPage]: '/addAdditionalDrivers',
    [SciPagesEnum.VehicleProtections]: '/vehicleProtections',
    [SciPagesEnum.Extras]: '/extras',
    [SciPagesEnum.Payment]: '/payment',
};

export const SCIDefaultFlowPaths = [
    {
        label: '1',
        isCompleted: false,
        path: '/',
        pageEnum: SciPagesEnum.RetrieveSelfCheckInPage,
    },
    {
        label: '2',
        isCompleted: false,
        path: Path[SciPagesEnum.UpdateCustomerPage],
        pageEnum: SciPagesEnum.UpdateCustomerPage,
    },
    {
        label: '3',
        isCompleted: false,
        path: Path[SciPagesEnum.AddDriverPage],
        pageEnum: SciPagesEnum.AddDriverPage,
    },
    {
        label: '4',
        isCompleted: false,
        path: Path[SciPagesEnum.AddAdditionalDriversPage],
        pageEnum: SciPagesEnum.AddAdditionalDriversPage,
    },
    {
        label: '5',
        isCompleted: false,
        path: Path[SciPagesEnum.VehicleProtections],
        pageEnum: SciPagesEnum.VehicleProtections,
    },
    {
        label: '6',
        isCompleted: false,
        path: Path[SciPagesEnum.Extras],
        pageEnum: SciPagesEnum.Extras,
    },
    {
        label: '7',
        isCompleted: false,
        path: Path[SciPagesEnum.Payment],
        pageEnum: SciPagesEnum.Payment,
    },
];

export const SCISpecificFlow: SciFlow[] = [
    {
        condition: (args) =>
            [organizationIds[CosmosEnums.CountryCode.UK]].includes(
                args.orgId,
            ) && !args.isRelocation,
        paths: [
            {
                label: '1',
                isCompleted: false,
                path: '/',
                pageEnum: SciPagesEnum.RetrieveSelfCheckInPage,
            },
            {
                label: '2',
                isCompleted: false,
                path: Path[SciPagesEnum.UpdateCustomerPage],
                pageEnum: SciPagesEnum.UpdateCustomerPage,
            },
            {
                label: '3',
                isCompleted: false,
                path: Path[SciPagesEnum.AddDriverPage],
                pageEnum: SciPagesEnum.AddDriverPage,
            },
            {
                label: '4',
                isCompleted: false,
                path: Path[SciPagesEnum.AddAdditionalDriversPage],
                pageEnum: SciPagesEnum.AddAdditionalDriversPage,
            },
            {
                label: '5',
                isCompleted: false,
                path: Path[SciPagesEnum.VehicleProtections],
                pageEnum: SciPagesEnum.VehicleProtections,
            },
            {
                label: '6',
                isCompleted: false,
                path: Path[SciPagesEnum.Payment],
                pageEnum: SciPagesEnum.Payment,
            },
        ],
    },
    {
        condition: (args) =>
            [
                organizationIds[CosmosEnums.CountryCode.AU],
                organizationIds[CosmosEnums.CountryCode.NZ],
            ].includes(args.orgId) && args.isRelocation,
        paths: [
            {
                label: '1',
                isCompleted: false,
                path: '/',
                pageEnum: SciPagesEnum.RetrieveSelfCheckInPage,
            },
            {
                label: '2',
                isCompleted: false,
                path: Path[SciPagesEnum.UpdateCustomerPage],
                pageEnum: SciPagesEnum.UpdateCustomerPage,
            },
            {
                label: '3',
                isCompleted: false,
                path: Path[SciPagesEnum.AddDriverPage],
                pageEnum: SciPagesEnum.AddDriverPage,
            },
            {
                label: '4',
                isCompleted: false,
                path: Path[SciPagesEnum.AddAdditionalDriversPage],
                pageEnum: SciPagesEnum.AddAdditionalDriversPage,
            },
        ],
    },
    {
        condition: (args) =>
            [organizationIds[CosmosEnums.CountryCode.UK]].includes(
                args.orgId,
            ) && args.isRelocation,
        paths: [
            {
                label: '1',
                isCompleted: false,
                path: '/',
                pageEnum: SciPagesEnum.RetrieveSelfCheckInPage,
            },
            {
                label: '2',
                isCompleted: false,
                path: Path[SciPagesEnum.UpdateCustomerPage],
                pageEnum: SciPagesEnum.UpdateCustomerPage,
            },
            {
                label: '3',
                isCompleted: false,
                path: Path[SciPagesEnum.AddDriverPage],
                pageEnum: SciPagesEnum.AddDriverPage,
            },
            {
                label: '4',
                isCompleted: false,
                path: Path[SciPagesEnum.AddAdditionalDriversPage],
                pageEnum: SciPagesEnum.AddAdditionalDriversPage,
            },
            {
                label: '5',
                isCompleted: false,
                path: Path[SciPagesEnum.Payment],
                pageEnum: SciPagesEnum.Payment,
            },
        ],
    },
];
