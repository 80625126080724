import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { B2C, NotificationBanner } from 'cosmos-components';

import { Checkbox, Input, FormattedInput, Select } from 'src/components/form';
import { Row } from 'src/components/shared/Row';
import { Column } from 'src/components/shared/Column';
import { NotificationBannerMessage } from 'src/components/shared/NotificationBannerMessage';
import globalMsg from 'src/messages';
import {
    minimumPaymentAmount,
    sciContentKeys,
    ThlWebChatUrl,
} from 'src/constants';
import { useSciContent } from 'src/hooks/useSciContent';
import { intlLink } from 'src/utils/intlHelper';
import { UkBrandNames, UsBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { useConfiguration } from 'src/hooks/useConfiguration';

import * as SForm from './payment.form.style';

import { PaymentMethodCard } from './paymentMethodCard.component';
import { PaymentFormValues, PaymentIssuerResponse } from './payment.type';
import { PaymentMethodEnum } from './payment.enum';
import msg from './payment.messages';

import { MODULE_PREFIX } from './payment.constants';

export const selectIssuers = `${MODULE_PREFIX}-select-issuers`;
export const inputCardNumber = `${MODULE_PREFIX}-input-card-number`;
export const inputCardHolderName = `${MODULE_PREFIX}-input-card-holder-name`;
export const inputCardExpiry = `${MODULE_PREFIX}-input-card-expiry`;
export const inputCardCvv = `${MODULE_PREFIX}-input-card-cvv`;
export const inputTC = `${MODULE_PREFIX}-input-card-tc`;
export const inputSciSafetyVideosAcceptance = `${MODULE_PREFIX}-input-card-sciSafetyVideosAcceptance`;

export const PaymentForm = ({
    paymentAmount,
    paymentIssuers,
    paymentIssuerLoading,
    paymentSurcharge,
    paymentSurchargeIsLoading,
    needToConsiderSurcharge,
    isPaymentInProgress,
    selectedPaymentMethod,
    selectedPaymentIssuer,
    error,
    paymentError,
    control,
    onSubmit,
    onSubmitManual,
}: {
    paymentAmount: number;
    paymentIssuers: PaymentIssuerResponse[];
    paymentIssuerLoading: boolean;
    paymentSurcharge?: number;
    paymentSurchargeIsLoading: boolean;
    needToConsiderSurcharge?: boolean;
    isPaymentInProgress?: boolean;
    selectedPaymentMethod?: PaymentMethodEnum;
    selectedPaymentIssuer?: string;
    error?: string | React.ReactNode;
    paymentError?: string | React.ReactNode;
    control: Control<PaymentFormValues>;
    onSubmit: () => void;
    onSubmitManual: () => void;
}) => {
    const intl = useIntl();
    const configuration = useConfiguration();
    const brandName = useSelector(sciBrandNameSelector);
    const displayLinkInError = !Object.values(UkBrandNames).includes(
        brandName as UkBrandNames,
    );
    const hideManualPayment = [
        ...Object.values(UkBrandNames),
        ...Object.values(UsBrandNames),
    ].includes(brandName as UkBrandNames | UsBrandNames);

    const paymentMethodLabel = intl.formatMessage(msg.label.paymentMethod);
    const issuerLabel = intl.formatMessage(msg.label.issuer);
    const cardNumberLabel = intl.formatMessage(msg.label.cardNumber);
    const cardHolderNameLabel = intl.formatMessage(msg.label.cardHolderName);
    const cardExpiryDateLabel = Object.values(UsBrandNames).includes(
        brandName as UsBrandNames,
    )
        ? intl.formatMessage(msg.label.cardExpiryUS)
        : intl.formatMessage(msg.label.cardExpiry);

    const cardCVVLabel = intl.formatMessage(msg.label.cardCVV);
    const genericErrorMessage = displayLinkInError
        ? intl.formatMessage(msg.backendError.genericWithLink, {
              a: intlLink(ThlWebChatUrl),
          })
        : intl.formatMessage(msg.backendError.generic);

    const {
        data: tcHtml,
        isLoading: tcHtmlIsLoading,
        isError: tcHtmlIsError,
    } = useSciContent(sciContentKeys.termsAndConditionsAcceptance);

    const {
        data: sciSafetyVideosAcceptanceHtml,
        isLoading: sciSafetyVideosAcceptanceHtmlIsLoading,
        isError: sciSafetyVideosAcceptanceHtmlIsError,
    } = useSciContent(sciContentKeys.sciSafetyVideosAcceptance);

    const {
        data: infoBoxCardAdminFeeHtml,
        isLoading: infoBoxCardAdminFeeHtmlIsLoading,
    } = useSciContent(sciContentKeys.infoBoxCardAdminFee);

    const { data: bankTransferHtml, isLoading: bankTransferHtmlIsLoading } =
        useSciContent(sciContentKeys.bankTransferInfo);

    const cardExpiryDatePlaceHolder = intl.formatMessage(
        msg.placeHolder.cardExpiry,
    );

    const cardCVVPlaceHolder = intl.formatMessage(msg.placeHolder.cardCVV);

    const cardTypeSubLabel = intl.formatMessage(msg.subLabel.cardType, {
        amount: <b>${paymentSurcharge?.toFixed(2)}</b>,
    });

    const isLoading =
        paymentIssuerLoading ||
        tcHtmlIsLoading ||
        sciSafetyVideosAcceptanceHtmlIsLoading ||
        bankTransferHtmlIsLoading ||
        infoBoxCardAdminFeeHtmlIsLoading;

    const _onSubmit = (e: any) => {
        e.preventDefault();

        if (selectedPaymentMethod === PaymentMethodEnum.Manual) {
            onSubmitManual();
        } else {
            onSubmit();
        }
    };

    const errorMessage =
        paymentError ||
        error ||
        ((tcHtmlIsError || sciSafetyVideosAcceptanceHtmlIsError) &&
            genericErrorMessage);

    const availablePaymentMethod = Object.keys(PaymentMethodEnum);
    if (hideManualPayment) {
        const indexOfManualPaymentIndex = availablePaymentMethod.indexOf(
            PaymentMethodEnum.Manual,
        );
        availablePaymentMethod.splice(indexOfManualPaymentIndex, 1);
    }

    return (
        <SForm.StyledForm onSubmit={_onSubmit}>
            <Controller
                control={control}
                name="paymentMethod"
                render={({ field }) => {
                    return configuration.functionalities.payments
                        .showMethods ? (
                        <>
                            <SForm.Header>
                                <SForm.HeaderText>
                                    {paymentMethodLabel}
                                </SForm.HeaderText>
                                {needToConsiderSurcharge && (
                                    <SForm.StyleContainedModal
                                        dialogInfo={
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        infoBoxCardAdminFeeHtml ??
                                                        '',
                                                }}
                                            ></div>
                                        }
                                    />
                                )}
                            </SForm.Header>
                            <SForm.PaymentMethodContainer>
                                {availablePaymentMethod.map((paymentMethod) => {
                                    const selected =
                                        paymentMethod === field.value;

                                    return (
                                        <PaymentMethodCard
                                            key={paymentMethod}
                                            disabled={paymentSurchargeIsLoading}
                                            loading={
                                                paymentSurchargeIsLoading &&
                                                selected
                                            }
                                            paymentMethod={
                                                paymentMethod as PaymentMethodEnum
                                            }
                                            selected={selected}
                                            onSelect={() =>
                                                field.onChange(
                                                    paymentMethod as PaymentMethodEnum,
                                                )
                                            }
                                        />
                                    );
                                })}
                            </SForm.PaymentMethodContainer>
                        </>
                    ) : (
                        <></>
                    );
                }}
            />
            <SForm.PaymentCardContainer>
                {isLoading ? (
                    <B2C.LoaderSpinner size="large" />
                ) : selectedPaymentMethod === PaymentMethodEnum.Manual ? (
                    bankTransferHtml && (
                        <div
                            data-test-id={`${MODULE_PREFIX}-manual-content`}
                            dangerouslySetInnerHTML={{
                                __html: bankTransferHtml,
                            }}
                        />
                    )
                ) : (
                    <>
                        {configuration.functionalities.payments
                            .showCardType && (
                            <Row>
                                <Column size={12}>
                                    <Select
                                        testId={selectIssuers}
                                        name="issuer"
                                        control={control}
                                        label={issuerLabel}
                                        options={[
                                            '',
                                            ...R.uniq(
                                                paymentIssuers?.map(
                                                    (paymentIssuer) =>
                                                        paymentIssuer.name,
                                                ) ?? [],
                                            ),
                                        ]}
                                    />
                                    <SForm.SubLabel>
                                        {selectedPaymentIssuer &&
                                            needToConsiderSurcharge &&
                                            (paymentSurchargeIsLoading ? (
                                                <B2C.LoaderSpinner
                                                    size="small"
                                                    position="start"
                                                />
                                            ) : (
                                                cardTypeSubLabel
                                            ))}
                                    </SForm.SubLabel>
                                </Column>
                            </Row>
                        )}
                        {paymentAmount >= minimumPaymentAmount && (
                            <Row>
                                <FormattedInput
                                    testId={inputCardNumber}
                                    name="cardNumber"
                                    format="#### #### #### ####"
                                    control={control}
                                    label={cardNumberLabel}
                                />
                            </Row>
                        )}
                        {paymentAmount >= minimumPaymentAmount && (
                            <Row>
                                <Input
                                    testId={inputCardHolderName}
                                    name="cardHolderName"
                                    control={control}
                                    label={cardHolderNameLabel}
                                />
                            </Row>
                        )}
                        {paymentAmount >= minimumPaymentAmount && (
                            <Row>
                                <Column size={6}>
                                    <FormattedInput
                                        testId={inputCardExpiry}
                                        name="cardExpiryDate"
                                        control={control}
                                        format="##/##"
                                        label={cardExpiryDateLabel}
                                        placeholder={cardExpiryDatePlaceHolder}
                                    />
                                </Column>
                                <Column size={6}>
                                    <Input
                                        testId={inputCardCvv}
                                        name="cardCVV"
                                        control={control}
                                        label={cardCVVLabel}
                                        placeholder={cardCVVPlaceHolder}
                                        inputRestProps={{
                                            maxLength: 4,
                                        }}
                                    />
                                </Column>
                            </Row>
                        )}
                        <Row>
                            <Checkbox
                                testId={inputTC}
                                name="tc"
                                control={control}
                                labelHtml={tcHtml}
                                labelPosition={'after'}
                            />
                        </Row>
                        {configuration.functionalities
                            .sciSafetyVideosAcceptance && (
                            <Row>
                                <Checkbox
                                    testId={inputSciSafetyVideosAcceptance}
                                    name="sciSafetyVideosAcceptance"
                                    control={control}
                                    labelHtml={sciSafetyVideosAcceptanceHtml}
                                    labelPosition={'after'}
                                />
                            </Row>
                        )}
                    </>
                )}
                <SForm.ActionWrapper>
                    <SForm.StyledPrimaryButton
                        data-test-id={`${MODULE_PREFIX}-save`}
                        type="submit"
                        disabled={
                            paymentSurchargeIsLoading ||
                            paymentIssuerLoading ||
                            isPaymentInProgress ||
                            error
                        }
                    >
                        {paymentAmount < minimumPaymentAmount ||
                        selectedPaymentMethod === PaymentMethodEnum.Manual
                            ? intl.formatMessage(globalMsg.buttons.continue)
                            : intl.formatMessage(globalMsg.buttons.payNow)}
                    </SForm.StyledPrimaryButton>
                </SForm.ActionWrapper>
            </SForm.PaymentCardContainer>
            {errorMessage && (
                <NotificationBanner
                    message={
                        <NotificationBannerMessage
                            header={
                                <strong>
                                    {intl.formatMessage(
                                        globalMsg.errors.someThingWrong,
                                    )}
                                </strong>
                            }
                            content={
                                <>
                                    <SForm.SubLabel>
                                        {errorMessage}
                                    </SForm.SubLabel>
                                </>
                            }
                        />
                    }
                    type={'error'}
                    isClosable={false}
                    notificationIconSize="medium1"
                    hasBorder
                    isNotificationIconFilled
                />
            )}
        </SForm.StyledForm>
    );
};
