import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import countryCallingCode from 'country-calling-code';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { AddressInfo } from 'src/components/shared/GooglePlacesAutoComplete';
import { useSci } from 'src/hooks/useSci';
import { isAscii } from 'src/utils/string';
import { languageOptions } from 'src/constants';
import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { getSortedCountryNames } from 'src/utils';

import { updateCustomerInfoAction } from './updateCustomerInfo.duck';
import {
    createShuttleAction,
    deleteShuttleAction,
    retrieveCustomerInfoAction,
} from './retrieveCustomerInfo.duck';

import { CustomerInfo, EmailErrorType, ShuttleInfo } from './types';

import {
    countriesIsLoadingSelector,
    countriesObjectSelector,
    countrySelector,
    customerInfoSelector,
    driversCountSelector,
    primaryProductIsLoadingSelector,
    primaryProductSelector,
    retrieveIsLoadingSelector,
    updateErrorSelector,
    updateIsLoadingSelector,
} from './updateCustomerInfo.selector';
import msg from './updateCustomerInfoMessages';
import { retrieveCountriesAction } from './retrieveCountries.duck';
import { retrievePrimaryProductInfoAction } from './retrievePrimaryProductInfo.duck';
import { retrieveOrganizationSettingsAction } from './retrieveOrganizationSettings.duck';
import { useGetShuttleService } from './shuttleService/shuttleService.hook';
import { formatShuttleKey } from './utils';
import { lookupCountry } from 'src/utils/coutries';

export const useUpdateCustomerInfoForm = () => {
    const [isDriverDisabled, setIsDriverDisabled] = useState(false);
    const [emailError, setEmailError] = useState<EmailErrorType>('');
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { shuttlePoints } = useGetShuttleService();
    const selfCheckIn = useSci();

    const customerInfo = useSelector(customerInfoSelector);
    const driversCount = useSelector(driversCountSelector);
    const intl = useIntl();
    const schema = yup.object().shape({
        email: yup
            .string()
            .email(intl.formatMessage(msg.fields.validEmail))
            .required(intl.formatMessage(msg.fields.enterEmail)),
        firstName: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterFirstName)),
        lastName: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterLastName)),
        mobile: yup.object().shape({
            numberWithoutCountryCode: yup
                .string()
                .required(intl.formatMessage(msg.fields.enterMobile)),
        }),
        phone: yup.object().shape({
            numberWithoutCountryCode: yup.string(),
        }),
        address: yup.object().shape({
            line1: yup
                .string()
                .required(intl.formatMessage(msg.fields.enterHomeAddress)),
            city: yup
                .string()
                .required(intl.formatMessage(msg.fields.enterCity)),
            postCode: yup
                .string()
                .required(intl.formatMessage(msg.fields.enterPostCode)),
            country: yup
                .string()
                .required(intl.formatMessage(msg.fields.selectCountry)),
            countryCode: yup.string(),
            state: yup
                .string()
                .required(intl.formatMessage(msg.fields.selectState)),
        }),
        adultsNum: yup.number().required(),
        childrenNum: yup.number().required(),
        isShuttleSelected: yup.boolean(),
        shuttleSelected: yup.string().when('isShuttleSelected', {
            is: (isShuttleSelected: boolean) => !!isShuttleSelected,
            then: (yupSchema) =>
                yupSchema.required(
                    intl.formatMessage(msg.fields.selectShuttle),
                ),
        }),
        loyaltyProgramMembershipCode: yup.string(),
        preferredLanguage: yup.object().shape({
            name: yup.string(),
            locale: yup.string(),
        }),
    });
    const primaryProduct = useSelector(primaryProductSelector);
    const updateIsLoading = useSelector(updateIsLoadingSelector);
    const retrieveIsLoading = useSelector(retrieveIsLoadingSelector);
    const countriesIsLoading = useSelector(countriesIsLoadingSelector);
    const primaryProductIsLoading = useSelector(
        primaryProductIsLoadingSelector,
    );
    const country = useSelector(countrySelector);
    const updateError = useSelector(updateErrorSelector);
    const countries = useCountrySelect();
    const sciLanguage = useSelector(sciLanguageSelector);

    customerInfo.adultsNum = selfCheckIn?.data?.rental?.numberOfAdults ?? 0;
    customerInfo.childrenNum = selfCheckIn?.data?.rental?.numberOfChildren ?? 0;
    customerInfo.maxPassengers = primaryProduct.maxPassengers;
    customerInfo.maxAdults = primaryProduct.maxAdults;
    customerInfo.maxChildren = primaryProduct.maxChildren;

    const {
        control,
        formState,
        handleSubmit,
        setValue,
        reset,
        getValues,
        setError,
        clearErrors,
        trigger,
        watch,
    } = useForm<CustomerInfo & ShuttleInfo>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<CustomerInfo & ShuttleInfo> = (data) => {
        if (data.adultsNum + data.childrenNum > primaryProduct.maxPassengers) {
            setError('adultsNum', {
                type: 'required',
                message: intl.formatMessage(msg.fields.passengersExceeded),
            });
            setError('childrenNum', {
                type: 'required',
                message: intl.formatMessage(msg.fields.passengersExceeded),
            });
            return false;
        } else if (data.adultsNum < driversCount) {
            setError('adultsNum', {
                type: 'min',
                message: intl.formatMessage(msg.fields.selectAdults),
            });
            return false;
        } else {
            clearErrors(['adultsNum', 'childrenNum']);
        }
        const prepareUpdateCustomerInfoPayload = (
            customerInfoData: CustomerInfo,
        ) => {
            return {
                rental: {
                    ...(selfCheckIn?.data?.rental ?? {}),
                    numberOfAdults: customerInfoData.adultsNum,
                    numberOfChildren: customerInfoData.childrenNum,
                },
                hirer: {
                    id: customerInfoData.id,
                    email: customerInfoData.email,
                    firstName: customerInfoData.firstName,
                    lastName: customerInfoData.lastName,
                    address: customerInfoData.address,
                    phone: customerInfoData.phone,
                    mobile: customerInfoData.mobile,
                    driver: data.isDriver,
                    preferredLanguage: customerInfoData.preferredLanguage,
                },
            };
        };
        dispatch(
            updateCustomerInfoAction(prepareUpdateCustomerInfoPayload(data)),
        );
        if (shuttlePoints && shuttlePoints.length > 0) {
            if (data.isShuttleSelected) {
                const shuttleSelected = shuttlePoints.find(
                    (s) => formatShuttleKey(s) === data.shuttleSelected,
                );
                dispatch(createShuttleAction(shuttleSelected));
            } else {
                dispatch(deleteShuttleAction());
            }
        }
    };
    useEffect(() => {
        if (customerInfo) {
            if (customerInfo.adultsNum === 1) {
                customerInfo.isDriver = true;
                setIsDriverDisabled(true);
            }
            reset(customerInfo);
        }
        if (country && customerInfo) {
            const phone = getValues('phone');
            const mobile = getValues('mobile');
            const countryCode = countryCallingCode.find(
                (code) =>
                    code.isoCode2 === country.code ||
                    code.isoCode3 === country.code,
            );
            if (!phone.number) {
                setValue('phone', {
                    ...phone,
                    countryCode: countryCode?.countryCodes[0] ?? '64',
                });
            }

            if (!mobile?.number) {
                setValue('mobile', {
                    ...mobile,
                    countryCode: countryCode?.countryCodes[0] ?? '64',
                });
            }
        }
    }, [customerInfo, country, getValues, setValue, reset]);

    const handleGooglePlacesAutoCompleteSelect = (addressInfo: AddressInfo) => {
        console.log({
            addressInfo,
            test:
                addressInfo.line1 && isAscii(addressInfo.line1)
                    ? addressInfo.line1
                    : `${addressInfo.number} ${addressInfo.street}`,
        });
        setValue('address', {
            line1: '',
            line2: '',
            postCode: '',
            city: '',
            state: '',
            country: '',
            countryCode: '',
        });
        setValue(
            'address.line1',
            addressInfo.line1 && isAscii(addressInfo.line1)
                ? addressInfo.line1
                : `${addressInfo.number} ${addressInfo.street}`,
        );
        setValue(
            'address.line2',
            isAscii(addressInfo.line2 ?? '') ? addressInfo.line2 : '',
        );
        setValue('address.postCode', addressInfo.postCode);
        setValue(
            'address.city',
            isAscii(addressInfo.city ?? '') ? addressInfo.city : '',
        );
        setValue(
            'address.state',
            isAscii(addressInfo.state ?? '') ? addressInfo.state : '',
        );
        setValue(
            'address.country',
            isAscii(addressInfo.country) ? addressInfo.country : '',
        );
        setValue(
            'address.countryCode',
            isAscii(addressInfo.countryCode) ? addressInfo.countryCode : '',
        );
        trigger([
            'address.line1',
            'address.line2',
            'address.postCode',
            'address.city',
            'address.state',
            'address.country',
        ]);
    };

    const setPhoneOrMobileObject = (key: 'phone' | 'mobile', value: any) => {
        setValue(
            key,
            {
                number: `+${value.countryCode}${value.phoneNumber}`,
                countryCode: value.countryCode,
                numberWithoutCountryCode: value.phoneNumber,
                id:
                    key === 'phone'
                        ? customerInfo.phone.id
                        : customerInfo.mobile.id,
            },
            { shouldValidate: true },
        );
    };

    const isFormLoading =
        retrieveIsLoading ||
        updateIsLoading ||
        countriesIsLoading ||
        primaryProductIsLoading;

    const onAdultsItemSelected = (adultsNum: number) => {
        if (adultsNum > 1) {
            setIsDriverDisabled(false);
        } else if (adultsNum === 1) {
            customerInfo.isDriver = true;
            setValue('isDriver', customerInfo.isDriver);
            setIsDriverDisabled(true);
        }
    };
    type DropdownKeyType = 'adultsNum' | 'childrenNum';

    const onPassengerChange = (
        key: DropdownKeyType,
        value: number,
        complementKey: DropdownKeyType,
    ) => {
        const complementValue = +getValues(complementKey);
        if (complementValue + value > customerInfo.maxPassengers) {
            setError(key, {
                type: 'required',
                message: intl.formatMessage(msg.fields.passengersExceeded),
            });
        } else if (
            (key === 'adultsNum' && value < driversCount) ||
            (complementKey === 'adultsNum' && complementValue < driversCount)
        ) {
            setError('adultsNum', {
                type: 'min',
                message: intl.formatMessage(msg.fields.selectAdults),
            });
        } else {
            clearErrors([key, complementKey]);
        }
    };

    const onCountryChange = async (country: string) => {
        const countryInfo = await lookupCountry(country);

        setValue('address.countryCode', countryInfo.countryCode ?? '');
    };

    const onPreferredLanguageChange = async (language: string) => {
        const langOpt =
            languageOptions.find((opt) => opt?.value === language) ||
            languageOptions[0];
        searchParams.set('lng', langOpt?.label?.toLowerCase());
        setSearchParams(searchParams);

        setValue('preferredLanguage.name', langOpt?.value);
        setValue('preferredLanguage.locale', langOpt?.id);
    };

    const onLoyaltyMembershipChange = (
        loyaltyProgramMembershipCode?: string,
    ) => {
        setValue('loyaltyProgramMembershipCode', loyaltyProgramMembershipCode);
    };

    useEffect(() => {
        dispatch(retrieveCustomerInfoAction());

        if (countries.length === 0) {
            dispatch(retrieveCountriesAction());
        }
        if (!primaryProduct.id.productId) {
            dispatch(retrievePrimaryProductInfoAction());
        }
    }, [dispatch, countries.length, primaryProduct.id.productId]);

    useEffect(() => {
        dispatch(retrieveCountriesAction());
    }, [dispatch, sciLanguage]);

    useEffect(() => {
        if (selfCheckIn?.data?.id?.organizationId) {
            dispatch(
                retrieveOrganizationSettingsAction({
                    organizationId: selfCheckIn?.data?.id?.organizationId,
                }),
            );
        }
    }, [dispatch, selfCheckIn?.data?.id?.organizationId]);

    useEffect(() => {
        if (updateError?.code === 'badRequest') {
            if (
                updateError.subMessage?.includes(
                    'The email address does not match the Customer details',
                )
            ) {
                setEmailError('mismatch');
            } else if (
                updateError.subMessage?.includes(
                    'Customer email already exists',
                )
            ) {
                setEmailError('exists');
            }
        }
    }, [updateError]);

    return {
        customerInfo,
        control,
        formState,
        trigger,
        onSubmit: handleSubmit(onSubmit),
        onGooglePlacesAutoCompleteSelect: handleGooglePlacesAutoCompleteSelect,
        setPhoneOrMobileObject,
        onAdultsItemSelected,
        isFormLoading,
        countries,
        isDriverDisabled,
        emailError,
        onPassengerChange,
        onCountryChange,
        onLoyaltyMembershipChange,
        onPreferredLanguageChange,
        country,
        isShuttleSelected: watch('isShuttleSelected'),
        loyaltyPrograms: selfCheckIn?.bookingSummaryData?.loyaltyPrograms,
    };
};

export const useCountrySelect = () => {
    const countries = useSelector(countriesObjectSelector);
    const configuration = useConfiguration();

    return getSortedCountryNames(
        countries,
        configuration.functionalities.customerDetails.suggestedCountryCodes,
    );
};
